export const API_URL = {
  index () {
    // return 'http://vmc_website_api.test'+API_PREFIX;
    // return 'http://222.127.23.107/vmc_website_api/public'+API_PREFIX;
    return 'https://www.veritas.com.ph/vmc_website_api/public'+API_PREFIX;
  }
}

export const API_PREFIX = '/api/public/'

export default { API_URL }
