<template>
  <div>
    <div id="VMCKeyVisual" class="carousel slide" data-ride="carousel">
      <div class="container">
        <ol class="carousel-indicators">
          <li data-target="#VMCKeyVisual" data-slide-to="0" class="active"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="1"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="2"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="3"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="4"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="5"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="6"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="7"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="8"></li>
          <li data-target="#VMCKeyVisual" data-slide-to="9"></li>
        </ol>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active parallax-effect" :style="{backgroundImage:'url('+banner12+')'}" id="BannerTwelve">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one"></p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two">CAPE HAYATE</p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner11+')'}" id="BannerEleven">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one"></p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two"></p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner10+')'}" id="BannerTen">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one"></p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two">CAPE NORMANDY</p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner9+')'}" id="BannerNine">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one"></p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two">CORONA MAJESTY</p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner7+')'}" id="BannerSeven">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one"></p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two"></p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner5+')'}" id="BannerFive">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one">SALUTE TO ALL OUR FRONTLINERS</p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two">
                TAGA VERITAS AKO!
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner6+')'}" id="BannerSix">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one">"TAGA VERITAS AKO!"</p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two">
                VERITAS FRONT LINERS
              </p>
              <p class="vmc-text-white font-weight-light caption-three"></p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner2+')'} " id="BannerTwo">
          <svg class="bd-placeholder-img" style="opacity: 50%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one">VERITAS GEARED TOWARDS</p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two">
                THE NEW NORMAL
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner4+')'}" id="BannerFour">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one"></p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two"></p>
              <p class="vmc-text-white font-weight-light caption-three"></p>
            </div>
          </div>
        </div>
        <div class="carousel-item parallax-effect" :style="{backgroundImage:'url('+banner1+')'}" id="BannerOne">
          <svg class="bd-placeholder-img" style="opacity: 55%" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--vmc-primary-3)"/>
          </svg>
          <div class="container">
            <div class="carousel-caption text-left">
              <p class="mb-2 font-weight-normal text-white caption-one">CARRYING A FAMILY LEGACY</p>
              <p class="vmc-text-white font-weight-normal mb-0 caption-two">
                TOWARD GROWTH, STABILITY
              </p>
              <p class="vmc-text-white font-weight-light caption-three">
                One of the country’s pioneers in the maritime manning sector, Veritas Maritime Corp. welcomed on July 11 its new leader, Francis Jerome “Jiro” Marquez, to family, friends and colleagues.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container bg-white">
      <div class="row py-3 d-flex align-items-center justify-content-center">
        <div class="col-auto p-1">
          <router-link class="btn vmc-btn-circle vmc-btn-secondary kv-low-btn" :to="{name: 'GovernmentAdvisories'}">
            ADVISORIES
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeyVisual",
  data() {
    return {
      'banner1': require(`@/assets/keyvisuals/banner1.jpg`),
      'banner2': require(`@/assets/keyvisuals/banner2_0.jpg`),
      'banner4': require(`@/assets/programs/office/2021/healthwealth/health1_18-10-2021.jpg`),
      'banner5': require(`@/assets/keyvisuals/Taga_Veritas_Ako_63021.jpg`),
      'banner6': require(`@/assets/keyvisuals/VeritasFront-Liners_7-12-2019.jpg`),
      'banner7': require(`@/assets/keyvisuals/mlcisocert_52022KV.jpg`),
      'banner9': require(`@/assets/keyvisuals/coronamajesty_053022KV.jpg`),
      'banner10': require(`@/assets/keyvisuals/capenormandy_053022KV.jpg`),
      'banner11': require(`@/assets/keyvisuals/intlSeafarer_62722KV.jpg`),
      'banner12': require(`@/assets/keyvisuals/capehayate_032024KV.jpg`)
    }
  },
  mounted() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const carousel1 = document.getElementById('BannerOne');
    const carousel2 = document.getElementById('BannerTwo');
    const carousel3 = document.getElementById('BannerFour');
    const carousel4 = document.getElementById('BannerFive');
    const carousel5 = document.getElementById('BannerSix');
    const carousel6 = document.getElementById('BannerSeven');
    const carousel8 = document.getElementById('BannerNine');
    const carousel9 = document.getElementById('BannerTen');
    const carousel10 = document.getElementById('BannerEleven');
    const carousel11 = document.getElementById('BannerTwelve');
    // const carousel10 = document.getElementById('BannerTen');

    if (w > 992 && w < h) {
      carousel1.style.height = "60vh"
      carousel2.style.height = "60vh"
      carousel3.style.height = "60vh"
      carousel4.style.height = "60vh"
      carousel5.style.height = "60vh"
      carousel6.style.height = "60vh"
      carousel8.style.height = "60vh"
      carousel9.style.height = "60vh"
      carousel10.style.height = "60vh"
      carousel11.style.height = "60vh"
      // carousel10.style.height = "60vh"
    }
    // console.log('width ' + w);
    // console.log('height ' + h);
  }
}
</script>

<style scoped>
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding-right: 2rem;
  left: auto;
  right: auto;
  z-index: 10;
  text-shadow: 1px 3px 6px rgba(0, 0, 0, .5);
}
.carousel-indicators {
  border-radius: 100px;
  bottom: 5%;
  margin: auto;
  left: auto;
  right: auto;
  justify-content: start;
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
/* Declare heights because of positioning of img element */
.carousel-item {
  height: calc(100vh - 100px);
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}

.parallax-effect {
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.kv-low-btn {
  width: 200px;
}
#VMCKeyVisual {
  margin-top: 85px;
}
.caption-one {
  letter-spacing: 3px;
  font-size: 14px;
}
.caption-two {
  font-size:40px;
  font-weight: 500;
  letter-spacing: 1px;
}
.caption-three {
  font-size: 16px;
}
@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption {
    width: 100%;
  }
  .carousel-caption p {
    width: 100%;
    margin-bottom: 1.25rem;
    line-height: 1.4;
  }
}
@media (min-width: 576px) {
  #VMCKeyVisual {
    margin-top: 135px;
  }
  .carousel-indicators {
    bottom: 20%;
  }
  .carousel-caption {
    max-width: 500px;
  }
  .caption-one {
    letter-spacing: 3px;
    font-size: 14px;
  }
  .caption-two {
    font-size:50px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .caption-three {
    font-size: 21px;
  }
}

@media (min-width: 768px) {
  #VMCKeyVisual {
    margin-top: 85px;
  }
  .carousel-indicators {
    bottom: 20%;
  }
  .carousel-caption {
    max-width: 680px;
  }
  .caption-one {
    letter-spacing: 3px;
    font-size: 16px;
  }
  .caption-two {
    font-size:55px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .caption-three {
    font-size: 23px;
  }
}

@media (min-width: 992px) {
  #VMCKeyVisual {
    margin-top: 105px;
  }
  .carousel-indicators {
    bottom: 20%;
  }
  .carousel-caption {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  #VMCKeyVisual {
    margin-top: 105px;
  }
  .carousel-indicators {
    bottom: 20%;
  }
  .carousel-caption {
    max-width: 1140px;
  }
}
</style>
