<template>
  <div class="news">
    <div class="container-fluid vmc-py-big">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="h1 vmc-text-primary vmc-heading">
              Veritas Christmas Video 2020
            </h2>
            <p class="vmc-text-primary-2 letter-spacing-1 mb-0">
              By Veritas Admin — December 25, 2020
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="my-3" style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/542517193?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Veritas 2020 Christmas Video"></iframe></div>
            <p>The talented and hardworking staff of Veritas made a video tribute for the Christmas season to remind us that we are one family facing this pandemic. </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <a href="https://vimeo.com/542517193/9106c60a94" target="_blank" class="mb-0 mt-3 vmc-text-primary-2" style="opacity: 75%">
              See Vimeo Video: https://vimeo.com/542517193/9106c60a94
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VeritasChristmasVideo2020',
  data() {
    return {
      'thumbnail': require(`@/assets/news/news_28-04-2021-XmasParty.jpg`),
    }
  }
};
</script>

<style scoped>

</style>
