<template>
  <div class="news">
    <div class="container-fluid vmc-py-big">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="h1 vmc-text-primary vmc-heading">
              Veritas 30th Anniversary Same Day Edit Video
            </h2>
            <p class="vmc-text-primary-2 letter-spacing-1 mb-0">
              By Veritas Admin — July 11, 2019
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="my-3" style="padding:46.88% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/542514942?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Veritas 30th Anniversary SDE"></iframe></div>
<!--            <iframe src="https://player.vimeo.com/video/542514942" width="640" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>-->
<!--            <img :src="thumbnail" alt="" class="my-3">-->
            <p>Same day video shoot and edit for the 30th-anniversary celebration of Veritas.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <a href="https://vimeo.com/542514942/18f46906e9" target="_blank" class="mb-0 mt-3 vmc-text-primary-2" style="opacity: 75%">
              See Vimeo Video: https://vimeo.com/542514942/18f46906e9
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Veritas30thAnniversarySameDayEditVideo',
  data() {
    return {
      'thumbnail': require(`@/assets/news/news_28-04-2021-30thAnniversary.jpg`),
    }
  }
};
</script>

<style scoped>

</style>
