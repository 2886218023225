<template>
  <div class="news">
    <div class="container-fluid vmc-py-big">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="h1 vmc-text-primary vmc-heading">
              Seafaring organization under proposed OFW dept pushed
            </h2>
            <p class="vmc-text-primary-2 letter-spacing-1 mb-0">
              By The Manila Times — March 10, 2021
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <img :src="veritasCrews" alt="" class="my-3">
            <p>With the Senate trying to rush the enactment of the new law creating the Department of Overseas Filipino Workers, the country’s biggest umbrella group in the manning sector has strongly lobbied for a dedicated organization for the seafaring sector under the proposed department.</p>
            <p>On the second public hearing on the proposed department for overseas Filipino workers (OFWs), the Senate committee on labor and employment has sought the private sector including the so-called civil society groups to present their respective positions on the proposal.</p>
            <p>In its first hearing, the Senate committee chaired by Sen. Joel Villanueva focused more on the response of various government agencies that would be affected by the creation of the new department, one of the campaign promises made by President Duterte to millions of OFWs.</p>
            <p>Testifying at last Monday’s public hearing, Ericson Marquez, former chairman of the Joint Manning Group (JMG), which accounts for 80 % of the country’s total deployment of seafarers overseas, stressed the uniqueness of the seafaring industry.</p>
            <p>He noted that for many years, the sector, which was initiated by the private sector, grew into a multibillion dollar industry mainly through their efforts. “Wala po kaming malaking problemang ibinibigay sa gobyerno dahil kami po ay highly organized industry,” the JMG official hastened to add.</p>
            <p>Furthermore, he said, the manning sector is one “industry where tripartism is highly evident in all our activities and discussions.</p>
            <p>He added that the manning sector is governed by international regulations.</p>
            <p>These are some of the reasons that make the seafaring profession unique and peculiar relative to land-based OFWs.</p>
            <p>Marquez refreshed the minds of the senators that even before the Philippine Overseas Employment Administration (POEA) was created, seafarers’ concerns and needs were already being handled by the National Seamen’s Board (NSB).</p>
            <p>If POEA would continue to handle the seafarers, Marquez appealed to the senators to designate a deputy administrator exclusively for the sea-based workers.</p>
            <p>If they would have a separate organization, he proposed the revival of NSB. “Ibalik ang National Seamen’s Board; (or under the new department), we strongly request one undersecretary that would be exclusively assigned to the seafaring sector to focus more on the need of the seafarers.</p>
            <p>Marquez found an ally during the hearing. His proposal for a separate organization for sea-based workers earned support from Sen. Imee Marcos, who recalled that even during the 1970s, seafarers have been already sending copious remittances to the national economy.</p>
            <p>“Konti lang sila pero malaki po ang ipinapadala sa atin dito sa Pilipinas, kaya kinikilala natin na dapat magkaroon sila ng separate na organization. They have unique requirements specific to their situation kaya kailangang may hiwalay na organization nakatutok exclusive sa kanila,” the lady senator emphasized.</p>
            <p>Her statements on seafarers’ significant remittances were corroborated by Labor Secretary Silvestre Bello III, who was also present during the hearing on the proposed bill, certified as urgent by President Duterte.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <a href="https://www.manilatimes.net/2021/03/10/business/maritime-business/seafaring-organization-under-proposed-ofw-dept-pushed/"
               target="_blank" class="mb-0 mt-3 vmc-text-primary-2" style="opacity: 75%">
              See Original Post By: The Manila Times
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarryingAFamilyLegacyTowardGrowthStability',
  data() {
    return {
      'veritasCrews': require(`@/assets/news/news_10-03-2021.jpg`),
    }
  }
};
</script>

<style scoped>

</style>