<template>
  <div class="news">
    <div class="container-fluid vmc-py-big">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="h1 vmc-text-primary vmc-heading">
              MESSAGE of H.E. TEODORO L. LOCSIN, JR. Secretary of Foreign Affairs on the Day of the Seafarer 25 June 2021
            </h2>
            <p class="vmc-text-primary-2 letter-spacing-1 mb-0">
              Embassy of the Republic of the Philippines — June 25, 2021
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="my-3 iframe-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/wkHzZ24eQfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>The Department of Foreign Affairs is honored to celebrate the Day of the Seafarer.</p>
            <p>
              This annual commemoration dedicated to seafarers recognizes their invaluable contribution to global trade and the world economy; and celebrates the seafaring fame of the Filipino since pre-Spanish times; which led the first Spanish Cardinal in Manila to tell the King of Spain of that for which the Filipino stands out: fighting and sailing.
            </p>
            <p>
              Seafaring is always difficult and frequently life-threatening; not just from storms at sea but from the deplorable working conditions in which it is undertaken with the notable exceptions of European and advanced country’s vessels like Japan’s. It has been worse in this pandemic.
            </p>
            <p>
              The pandemic has not been kind to anyone. But it has been especially harsh to seafarers. Many have lost their jobs. Many are unable to come home, stranded at sea with limited provisions due to quarantine restrictions on disembarkation and embarkation. Some have been stranded on board for as long as a year, in ports that will not allow them to disembark. This tragic situation has taken a toll on their wellbeing, mental health and on their families. Some have taken their own lives.
            </p>
            <p>
              This is unacceptable and outrageous. The DFA has led the charge in bringing back home our seafarers and fighting for their human right to crew change. Our government has established Green Lanes to facilitate movement of seafarers within our borders. We have also prioritized them in the vaccine program – as we should — as frontline workers. Every boat is a petri dish of deadly disease.
            </p>
            <p>
              Together with the International Maritime Organization we are pushing for a “Fair Future for Seafarers.” With the Philippines having over 337,000 seafarers, it is logical, good policy and just plain right and decent for us in government to ensure a good future for them. Let us not fail them; because that is like throwing them overboard with a makeshift anchor tied to their ankles.
            </p>
            <p>
              Hence we are pressing relevant agencies to address recurring negative findings of the European Maritime Safety Agency on the regulation of Maritime Higher Education Institutions. The future of a seafarer begins with the right training and proper schooling. We have been told again and again, indeed for 16 years that many of our schools are failing to provide this. And so far, we’ve come up only with excuses. As a result, close to 30,000 marine officers are in danger of losing their jobs on board European-registered vessels, the best-paying and the best-treating in the entire world. No others come close except those of Japan. This is the last thing we need.
            </p>
            <p>
              Under my watch we will go on condemning bad maritime schools that put our better ones in such a bad light; and we call on those tasked with the job to just do it. Meanwhile the DFA will go on fighting for working conditions for seafarers in other vessels; and we will continue pressing loudly for the right to repatriation and crew change.
              The DFA will always be there for the men and women who buoyed up our economy when it was listing, and who left family to find a better future for them. A meaningful Day of the Seafarer to all.
            </p>
            <p><i>#FairFuture4Seafarers</i></p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <a href="https://tokyo.philembassy.net/02events/%E6%97%A5%E6%9C%AC%E8%AA%9E-message-of-h-e-teodoro-l-locsin-jr-secretary-of-foreign-affairs-on-the-day-of-the-seafarer-25-june-2021/"
               target="_blank" class="mb-0 mt-3 vmc-text-primary-2" style="opacity: 75%">
              See Original Post By: Embassy of the Republic of the Philippines
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeodoroLocsinMessageDaySeafarer25June2021',
};
</script>

<style scoped>
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 52.25%;
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
