<template>
  <div class="vmc-careers">
    <div class="container-fluid vmc-py-big">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="h1 vmc-text-primary vmc-heading">
              Veritas Careers
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid vmc-py-big vmc-bg-dark-1">
      <div class="container">
        <div class="row">
          <div class="col-md-6 my-2">
            <div class="card">
              <div class="card-body">
                <h3>1. Executive Driver</h3>
                <h5>Qualifications:</h5>
                <ul>
                  <li>Candidate must possess at least a High School Diploma.</li>
                  <li>Candidate must possess a professional driver's license.</li>
                  <li>At least 2 year(s) of working experience in the related field is required for this position.</li>
                  <li>Knowledgeable in basic troubleshooting</li>
                  <li>Knowledgeable in Manila/Makati routes</li>
                  <li>Can drive manual and automatic transmission SUV vehicles</li>
                  <li>Applicants must be willing to work in Makati City.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="card">
              <div class="card-body">
                <h3>2. Executive Assistant</h3>
                <h5>Qualifications:</h5>
                <ul>
                  <li>Candidate must possess at least a Bachelor's/College Degree, any field.</li>
                  <li>At least 3 year(s) of working experience in the related field is required for this position.</li>
                  <li>Excellent communication skills.</li>
                  <li>Ability to function well in a high-paced environment</li>
                  <li>Highly proficient in MS Office applications, especially Excel, Word, PowerPoint and Outlook</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="card">
              <div class="card-body">
                <h3>3. Accounting/Finance Staff</h3>
                <h5>Qualifications:</h5>
                <ul>
                  <li>Bachelor's Degree in Accountancy, or any Finance-related courses.</li>
                  <li>With knowledge in General / Cost Accounting / Financial Accounting or Basic Accounting</li>
                  <li>Excellent communication skills.</li>
                  <li>Ability to function well in a high-paced environment</li>
                  <li>Highly proficient in MS Office applications, especially Excel, Word, and Outlook</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="card">
              <div class="card-body">
                <h3>4. Documentation Assistant</h3>
                <h5>Qualifications:</h5>
                <ul>
                  <li>Candidate must possess at least a Bachelor's/College Degree, any field.</li>
                  <li>Excellent communication skills.</li>
                  <li>Ability to function well in a high-paced environment</li>
                  <li>Highly proficient in MS Office applications, especially Excel, Word, and Outlook</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 my-2">
            <div class="card">
              <div class="card-body">
                <h3>5. Training Assistant</h3>
                <h5>Qualifications:</h5>
                <ul>
                  <li>Candidate must possess at least a Bachelor's/College Degree, any field.</li>
                  <li>Excellent communication skills.</li>
                  <li>Ability to function well in a high-paced environment</li>
                  <li>Highly proficient in MS Office applications, especially Excel, Word, and Outlook</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 my-4">
            <h4 class="vmc-text-primary text-center mb-0">
            Kindly send your resume to <a href="mailto:bea.aquino@veritas.com.ph">bea.aquino@veritas.com.ph</a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "CareersInVMC"
}
</script>

<style scoped>

</style>
