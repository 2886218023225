<template>
  <div class="news">
    <div class="container-fluid vmc-py-big">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="h1 vmc-text-primary vmc-heading">
              Seamans Wife Insights
            </h2>
            <p class="vmc-text-primary-2 letter-spacing-1 mb-0">
              By Veritas Admin — July 10, 2021
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="mt-3">
              As a wife of a seafarer, it is not an easy job or task simply because there is a lot of challenges that I encounter in our daily life.  Since my husband is not around or not living with us, I have to accept all responsibilities as a mother to my son. In the absence of the father, I am also the decision maker, implementing disciplinary measures and responsible for the house chores.
            </p>
            <p>
              Having a seaman for a husband is very difficult… its homesick versus dollar. It is hard for us to take the distance that separate us from my husband/father of my son, but what he wanted is for have a better future for the family as we are longing for him and the doubt, he feels for us. One of the greatest challenges that I would face as a seaman’s wife is loneliness. Being a with your spouse could fill your life with purpose since that is the person you choose to spend the rest of yours with. The amount of time spent out at sea can be a lot, surrounded by plenty of uncertainties.
            </p>
            <p>
              We all know the obligation and responsibility of our husband as a head of the family. One of the concerns regarding the period after the seafarer’s returns from the sea service is the wives cannot deal with repair and maintenance works and problem arise when the wife leaves the responsibility of these types of works of the seafarers. Holiday season is planned according to the desires of the seafarers. And also, all social activities, shopping and pleasures trips are made together with the seafarers. The reason for this to be the fact that the seafarers want to spend all his time with his family due to longing that they feel towards their wives and children since they were away from them for a long time.
            </p>
            <p>
              I am so blessed with my husband for his full support in our family. He is dedicated to his job as a sailor or mariner to the company where he belongs, Veritas Maritime Corporation. Through his job I’d have been finished my studies in Master’s degree and Ph.D. as a Doctor of Philosophy in Business Management. I made my life busy in teaching and as a tutor of my son while my husband is onboard.
            </p>
            <p>
              In terms of our finances and investment, it’s been tough since everything is for our future because the health condition of my husband is not good all the time. There are circumstances that when my husband comes back to their homeland, and then I wouldn’t know when he would go back to his work on sea. So, I need to save his hard-earned money for a certain period of time that he will use here on land.
            </p>
            <p>
              I consider my husband an exceptional provider, and we are truly fortunate that we are able to meet all our daily needs. Practically speaking, we have not really experienced financial deficit.
            </p>
            <p><strong><i>Joycelyn Carpio Orbe-Cerbo, Ph.D. </i></strong></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeamansWifeInsights',
};
</script>

<style scoped>

</style>
