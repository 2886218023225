<template>
  <div class="news">
    <div class="container-fluid vmc-py-big">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="h1 vmc-text-primary vmc-heading">
              My Personal Experience with Veritas Maritime Corporation
            </h2>
            <p class="vmc-text-primary-2 letter-spacing-1 mb-0">
              By Veritas Admin — July 10, 2021
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <img :src="staff" alt="" class="my-3" style="max-width: 500px">
            <p class="mt-3 text-left">
              I, Penelope L. Lumanog graduated on December 2019 at Centro Escolar University and looked for a job in various platforms such as jobstreet. I have applied to a lot of companies and went on interviews, but what really picked my interest and attention was the Veritas Maritime Corporation. When I had received an e-mail from this prestigious company, first thing I did is to search Veritas' website, and there I've seen the company's history, their vision and mission, and all of their accomplishments. I have also read how they value the loyalty of their employees. This made me even more interested to be a part of Veritas family. When I applied for the company, I had undergone a long process. I took several examinations, and went on an interview with the HR, Accounting Manager, General Manager, the President, and the Chairman of Veritas Maritime Corporation. This process made me realized how much I want to be a part of the company that really filters their employees to the very efficient and competent ones.
            </p>
            <p class="text-left">
              When I was finally accepted, I was really impressed by the company's working environment. The employees are punctual, student officer trainees are smart looking and attentive, the crews are skillful and even up to now I am motivated to work for Veritas Maritime Corporation everyday as I have witnessed respectful and approachable co-workers.
            </p>
            <p class="text-left">
              On March 2020, lockdown was implemented due to Covid-19 pandemic. Everything was affected, including Veritas Company. Number of businesses closed and a lot of people lost their jobs. But Veritas did not neglect its employees. In fact, they gave us assurance and security that we will get through this. We had zoom meetings with the top management almost every week to check on us and our families, and to check the state of our department and their employees. Our voices were heard and actions were taken. I witnessed the unity of each and every one and I appreciated these measures that the company has taken to be with us and I felt even more impressed and at the same time happy with where I am.
            </p>
            <p class="text-left">
              I'm beyond grateful that I am accepted to be a part of Veritas family and with them I can definitely see myself professionally growing as an individual applying the core values that the company is putting into practice. Now I know why most of the employees are loyal to them it’s because of the capability to balance work and life. Someday when I look back with all my achievements in life I’ll be proud saying <strong>“Taga Veritas Ako”.</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyPersonalExperiencePenelopeLumanog',
  data(){
    return {
      staff : require(`@/assets/news/newsStaff_7-10-2021.jpg`),
    }
  }
};
</script>

<style scoped>

</style>
